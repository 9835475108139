<script setup lang="ts">
import ComplexCard from '~/components/ComplexCard.vue'
import type { Complex, StatusCode } from '~/types'
import { statuses } from '~/types'

interface Props {
  limit?: number | null
}

const props = withDefaults(defineProps<Props>(), {
  limit: null
})

const route = useRoute()
const router = useRouter()

const config = useRuntimeConfig()
const activeSector = ref<string|null>(null)
const activeStatusId = ref<number|null>(route.query.status_id ? Number(route.query.status_id) : null)

watch(() => route.query.status_id, (statusId) => {
  activeStatusId.value = statusId ? Number(statusId) : null
})

type RawData = {
  sector_name_list: string[]
  body: Complex[]
  statuses: {
    id: number
    status: StatusCode
  }[]
}

const { data, pending } = await useFetch<RawData>(`${config.public.apiUrl}/complexes`, {
  query: {
    status_id: activeStatusId,
    sector: activeSector,
    per_page: props.limit
  }
})

function setActiveStatus(status: any) {
  const newValue = activeStatusId.value === status.id ? null : status.id
  const query = {
    ...route.query,
    status_id: newValue
  }

  if (newValue === null) delete query.status_id

  router.push({ query })
}
</script>

<template>
  <div class="complexes space">
    <div class="container">
      <div class="complexes__content">
        <div class="complexes__tabs">
          <div class="complexes__tabs-location tabs">
            <button
              class="complexes__tab tabs__item"
              :class="{ 'active': activeSector === null }"
              @click="activeSector = null"
            >
              {{ $t('all') }}
            </button>

            <button
              v-for="sector in (data as RawData)?.sector_name_list"
              :key="sector"
              class="complexes__tab tabs__item"
              :class="{ 'active': sector === activeSector }"
              @click="activeSector = sector"
            >
              {{ sector }}
            </button>
          </div>
          <div class="complexes__tabs-status tabs">
            <button
              v-for="status in (data as RawData)?.statuses"
              :key="status.status"
              class="complexes__tab tabs__item"
              :class="{ 'active': status.id === activeStatusId }"
              @click="setActiveStatus(status)"
            >
              {{ statuses()[status.status] }}
            </button>
          </div>
        </div>
        <div v-auto-animate class="complexes__items">
          <template v-if="(data as RawData)?.body.length">
            <ComplexCard
              v-for="(complex, index) in (data as RawData)?.body"
              :key="complex.id"
              data-aos="zoom-in"
              :data-aos-delay="150 * index"
              data-aos-once="true"
              class="complexes__item"
              :complex="complex"
            />
          </template>

          <div v-else class="complexes__empty text-center">
            <span v-if="pending">...</span>
            <span v-else>{{ $t('noData') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/complexes';
</style>